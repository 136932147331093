import {useEffect, useState, useRef} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from "./Search.module.scss";

const Search = () => {

 const [name, setName] = useState("пока не работает");
 const [nickname, setNickname] = useState("");
 const [email, setEmail] = useState("");
 const [subscr_id, setSubscr] = useState("");
 const [userResult, setUserResult] = useState(null);
 const [cancellationResult, setCancellationResult] = useState(null);

  const handleSubmitEmail = async () => {

        console.log("handleSubmitEmail " + email);
        setCancellationResult(null)
        let searchResult;
        await fetch("https://1u68st9dui.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    email: email,
                }
            }),
        }).then(async (response) => {
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json.result)
                searchResult = json.result
                return json.result
            });
        });
        console.log(searchResult)
        setUserResult(searchResult)
    };

const handleSubmitName = async (event) => {
   setCancellationResult(null)
  };

const handleSetSubscription = async (user) => {
      setCancellationResult(null)
      let setSubscrResult;
              await fetch(" https://vg9yyyq13c.execute-api.us-east-1.amazonaws.com/dev/set_user_web_subscription", {
                  method: "POST",
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      body: {
                          user_id: user,
                          subscription_id: subscr_id
                      }
                  }),
              }).then(async (response) => {
                  let resultBody = response.json();
                  await resultBody.then(async (json) => {
                      console.log(json.result)
                      setSubscrResult = json.result
                      return json.result
                  });
              });

 };

  const handleSubmitNickname = async (event) => {
     setCancellationResult(null)
     let searchResult;
         await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_nickname", {
             method: "POST",
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify({
                 body: {
                    nickname: nickname.toLowerCase(),
                 }
             }),
         }).then(async (response) => {

             let resultBody = response.json();
             await resultBody.then(async (json) => {
                 console.log(json.result)
                 searchResult = json.result
                 return json.result
             });
         });
         console.log(searchResult)
         setUserResult(searchResult)

    };

 const cancelWebSubscription = async (id) => {
        setCancellationResult(null)
        let cancelResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/cancel_web_subscription", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    subscriptionId: id,
                }
            }),
        }).then(async (response) => {
            console.log("cancel subscription result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json)
                cancelResult = json
                return json
            });
        });
        console.log(cancelResult)
        setCancellationResult(cancelResult)
        return cancelResult
    };

  return (
  <>
    <div className={styles.mainContainer}>
      <Tabs>
         <TabList>
           <Tab>Users</Tab>
           <Tab>Subscriptions</Tab>
         </TabList>
         <TabPanel>
           <h2>Search users by email, nickname, name</h2>
               <div>
                 <label>Enter email:
                   <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                 </label>
                 <button onClick={handleSubmitEmail}>
                   	<span>Send</span>
                 </button>

               </div>
               <div>
                                 <label>Enter nickname:
                                    <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
                                 </label>

                                  <button onClick={handleSubmitNickname}>
                                      <span>Send</span>
                                  </button>
                              </div>
                              <div>
                                                 <label>Enter name:
                                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                                </label>

                                                <button onClick={handleSubmitName}>
                                                        <span>Send</span>
                                                </button>
                                             </div>
                           {userResult && <div>
                            <p style={{color:"green", fontSize:'46px'}}> User Profiles:  </p>
                            { userResult.users?.map(user  => (
                            <div className={styles.userCard}>

                               <p> User Id: {user.id}  </p>
                               <p> User Name: {user.name}  </p>
                               <p> User Email: {user.email}  </p>
                               <p> User Nickname: {user.nickname}  </p>
                               <p> User Authenticator: {user.authenticator}  </p>
                               <p> Date created: {new Date(user.created_date).toUTCString()}  </p>
                               <p> Last online time: { user.last_online_time !== null && user.last_online_time !== undefined
                               ? new Date(user.last_online_time).toUTCString()
                                : "User didn't log in yet"}  </p>
                               {   userResult.subscription.length === 0 &&  <div>
                                                      <label>Enter subscription id:
                                                      <input type="text" value={subscr_id} onChange={(e) => setSubscr(e.target.value)}/>
                                                      </label>
                                                      <button onClick={() => handleSetSubscription(user.id)}>
                                                      <span>Confirm</span>
                                                     </button>
                                                      </div> }
                            </div>
                            ))}

                           <p style={{color:"violet", fontSize:'36px'}}> Subscriptions:   </p>
                           { userResult.subscription?.map(subscription  => (

                           <div className={styles.subscrCard}>
                           <p style={{color:"blue", fontSize: '30px'}}>Price:  {subscription.price} </p>
                           <p>Subscription ID: {subscription.subscr_id} </p>
                           <p>Platform: {subscription.platform} </p>
                           <p>Purchase platform: {subscription.platform} </p>
                           <p>Date purchase: {subscription.date_purchased} </p>
                           <p>Subscription Duration: {subscription.duration} </p>
                           <p>Subscription status: {subscription.status} </p>
                             { ( (subscription.platform === 'stripe' || subscription.platform === 'paypal')
                                && cancellationResult === null && subscription.status !== "inactive")
                                 && <button onClick={() => {
                                               cancelWebSubscription(subscription.subscr_id)}}> Cancel </button> }
                            { ((subscription.platform === 'stripe' || subscription.platform === 'paypal') && cancellationResult)
                               && <div>
                                       <p> Cancellation Result: </p>
                                       <p> {cancellationResult.canceled ? "Cancelled" : "Not cancelled"} </p>
                                  </div>}

                          </div>

                             ))   }
                           </div>}
         </TabPanel>
         <TabPanel>
           <h2>Search subscriptions</h2>
         </TabPanel>
       </Tabs>
    </div>
    </>
  );
};
export default Search;