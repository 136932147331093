import {useEffect, useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate } from '../../services/authenticate';
import userpool from '../../services/userpool'
import styles from "./Authenticate.module.scss";
import { Button, TextField,Typography } from '@mui/material'

const Authenticate = () => {
 const Navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [loginErr,setLoginErr]=useState(null);

    const formInputChange = (formField, value) => {
     console.log("formInputChange")
      if (formField === "email") {
      console.log("formInputChange email")
        setEmail(value);
      }
      if (formField === "password") {
      console.log("formInputChange password")
        setPassword(value);
      }
    };


  const validation = () => {
  console.log("validation")
      return new Promise((resolve, reject) => {
        if (email === '' && password === '') {
          setEmailErr("Email is Required");
          setPasswordErr("Password is required")
          resolve({ email: "Email is Required", password: "Password is required" });
        }
        else if (email === '') {
          setEmailErr("Email is Required")
          resolve({ email: "Email is Required", password: "" });
        }
        else if (password === '') {
          setPasswordErr("Password is required")
          resolve({ email: "", password: "Password is required" });
        }
        else if (password.length < 6) {
          setPasswordErr("must be 6 character")
          resolve({ email: "", password: "must be 6 character" });
        }
        else {
          resolve({ email: "", password: "" });
        }
      });
    };

    const handleClick = () => {
    console.log("handleClick")
        setEmailErr("");
        setPasswordErr("");
        validation()
          .then((res) => {
           console.log("validation done")
            if (res.email === '' && res.password === '') {
            console.log("authenticate...")
              authenticate(email,password)
              .then(async(data)=>{
              console.log("authenticated")
                console.log(data);

                setLoginErr(null);
                let userCognitoId = data.idToken.payload.sub;
                await fetch("https://1u68st9dui.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                body: {
                                    cognito_id: userCognitoId,
                                }
                            }),
                        }).then(async (response) => {
                            let resultBody = response.json();
                            await resultBody.then(async (json) => {
                                console.log(json.result)
                                if(json.result.users[0].app_role !== null
                                        && json.result.users[0].app_role !== undefined
                                        && json.result.users[0].app_role === 'admin'){
                                        Navigate('/search');
                             } else{
                              setLoginErr("You don't have admin role! Please contact administrators");
                             }
                            });
                        });

              },(err)=>{
               console.log("authenticate error")
                console.log(err);
                setLoginErr(err.message)
              })
              .catch(err=>console.log(err))
            }
          }, err => console.log(err))
          .catch(err => console.log(err));
      }

  return (
  <>
  <div className={styles.mainContainer}>
  	<div className={styles.screen}>
  		<div className={styles.screenContent}>
  			<div className={styles.login}>
  				<div className={styles.loginField}>
  					<i className={`${styles.loginIcon} ${styles.fas}`}></i>
  					<input type="text" value={email} className={styles.loginInput} placeholder="Email" helperText={emailErr} onChange={(e) => formInputChange("email", e.target.value)}></input>
  				</div>
  				<div className={styles.loginField}>
  					<i className={`${styles.loginIcon} ${styles.fas}`}></i>
  					<input type="password" value={password} className={styles.loginInput} placeholder="Password"  helperText={passwordErr} onChange={(e) => { formInputChange("password", e.target.value) }}></input>
  				</div>
  				<button className={`${styles.button} ${styles.loginSubmit}`} onClick={handleClick}>
  					<span>Log In Now</span>
  					<i className={`${styles.buttonIcon}`}></i>
  				</button>
  				{loginErr && <span>{loginErr}</span>}

  			</div>
{/*   			<div className={styles.socialLogin}> */}
{/*   				<h3>log in via</h3> */}
{/*   				<div className={styles.socialIcons}> */}
{/*   					<a href="#" className={`${styles.socialLoginIcon} ${styles.fab}`}></a> */}
{/*   					<a href="#" className={`${styles.socialLoginIcon} ${styles.fab}`}></a> */}
{/*   					<a href="#" className={`${styles.socialLoginIcon} ${styles.fab}`}></a> */}
{/*   				</div> */}
{/*   			</div> */}
  		</div>
  		<div className={styles.screenBackground}>
  			<span className={`${styles.screenBackgroundShape} ${styles.screenBackgroundShape4}`}></span>
  			<span className={`${styles.screenBackgroundShape} ${styles.screenBackgroundShape3}`}></span>
  			<span className={`${styles.screenBackgroundShape} ${styles.screenBackgroundShape2}`}></span>
  			<span className={`${styles.screenBackgroundShape} ${styles.screenBackgroundShape1}`}></span>
  		</div>
  	</div>
  </div>
  </>
  );
};
export default Authenticate;