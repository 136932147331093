import { useEffect, useState } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import amplitude from 'amplitude-js';

import ReactPixel from 'react-facebook-pixel';

import queryString from 'query-string';

import Search from './pages/search/Search';
import Authenticate from './pages/authenticate/Authenticate';
import React from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

function App() {


    useEffect(() => {
        console.log("оl");


        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);


    }, []);


    return (
        <div className="App">
            <Routes>
              <Route path='/' element={<Authenticate />} />
          //   <Route path='search' element={<Search />} />
            </Routes>
          </div>
        );
}

export default App;
