import React from 'react';
import './styles/global.scss';
import './styles/reset.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from "redux";
import App from './App';
import { Provider } from 'react-redux';

const defaultState = {
  paymentPeriod: {}
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'SELECT_PERIOD':
      return {...state, paymentPeriod: action.payload}

    default:
      return state;
  }
}

const store = createStore(reducer);
const container = document.getElementById("root");
const root = createRoot(container);
const Root = () => {

    return (
        <BrowserRouter
         //basename={`/${basename}`}
         >
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    );
};

root.render(<Root />);
